<template>
    <div class="system-container">
        <el-row>
            <el-col :span="12">
                <el-row>
                    <el-button @click="saveCompanyInfo" type="primary" size="small">更新</el-button>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-upload
                        class="avatar-uploader"
                        :action="imageURL"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="logoImage" :src="logoImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <el-button style="top: 28px;
                            left: 120px;
                            position: absolute;" class="el-icon-upload" size="small">上传LOGO</el-button>
                        <div slot="tip" class="el-upload__text">70*45像素</div>
                    </el-upload>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-upload
                        class="avatar-uploader"
                        :action="imageURL"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleBGSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="backImg" :src="backImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <el-button style="top: 28px;
                            left: 120px;
                            position: absolute;" class="el-icon-upload" size="small">上传背景图</el-button>
                        <div slot="tip" class="el-upload__text">高清大图</div>
                    </el-upload>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <label for="" style="padding-right: 40px;">企业名称：</label>
                    <el-input v-model="companyInfo.cusName" style="width: 300px;"></el-input>
                </el-row>
                <div class="companyIcon" v-show="false">
                    <el-row>
                        <label for="" style="display: inline-block;
                            position: relative;
                            top: -28px;width: 88px;">机会点图标：</label>
                        <el-upload
                            style="display:inline-block;"
                            class="avatar-uploader"
                            :action="imageURL"
                            :headers="headersObj"
                            :show-file-list="false"
                            :on-success="handlePPSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="ppIconImage" :src="ppIconImage" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <el-button style="top: 2px;
                                left: 46px;
                                position: absolute;" class="el-icon-upload" size="small">上传LOGO</el-button>
                            <div slot="tip" class="el-upload__text">15*15像素，不大于20K</div>
                        </el-upload>
                    </el-row>
                    <el-row style="margin-top: 30px;">
                        <label for="" style="display: inline-block;
                                position: relative;
                                top: -28px;width: 88px;">门店图标：</label>
                        <el-upload
                            style="display:inline-block;"
                            class="avatar-uploader"
                            :action="imageURL"
                            :headers="headersObj"
                            :show-file-list="false"
                            :on-success="handleStoreSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="storeIconImage" :src="storeIconImage" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <el-button style="top: 2px;
                                left: 46px;
                                position: absolute;" class="el-icon-upload" size="small">上传LOGO</el-button>
                            <div slot="tip" class="el-upload__text">15*15像素，不大于20K</div>
                        </el-upload>
                        
                    </el-row>
                </div>
            </el-col>
            <el-col :span="12" v-if="false">
                <div>
                    预览：
                </div>
                <!-- 登录页 -->
                <el-row>
                    <div ref="loginPreview" class="">
                        <div>
                            <img src="">
                        </div>
                    </div>
                </el-row>
                <!-- 地图图例 -->
                <el-row>
                    <div ref="mapPrevie">

                    </div>
                </el-row>
            </el-col>
        </el-row>
        
        
    </div>
</template>

<script>
import constants from "@/constants/Map.constants.js";
import api from "@/api/index.js";
const gap = 100;
export default {
    name: "companyMaintain",
    data() {
        return {
            bufferList: [],
            model: "",
            imageURL: "",
            limitNum: 1,
            companyInfo:{},
            fileList: [],
            logoImage:"", // logoURL
            companyName: "", // 企业名称
            ppIconImage: "", // 机会点图标
            storeIconImage: "", // 门店图标
            backImg: "", // 背景图
            headersObj: {} // 请求头
        }
    },
    mounted(){
        this.imageURL = this.formatURL("common/uploadImage");
        this.initInfo();
        this.headersObj = {
            "time": new Date().getTime(),
            "token": sessionStorage.token || "",
            "timestamp": sessionStorage.timestamp || ""
        }
    },
    methods: {
        //企业信息
        initInfo() {
            api.companyInfo({
                cusNo: sessionStorage.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.companyInfo = res.data.companyInfo;
                    this.companyInfo.cusNo = sessionStorage.cusNo;
                    this.logoImage = this.formatURL(this.companyInfo.logo);
                    this.ppIconImage = this.formatURL(this.companyInfo.siteIcon);
                    this.storeIconImage = this.formatURL(this.companyInfo.storeIcon);
                    this.backImg = this.formatURL(this.companyInfo.backImg);
                }
            });
        },
        //保存企业信息
        saveCompanyInfo() {
            api.saveCompanyInfo(this.companyInfo).then(res => {
                if(res.code == 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                } else {
                    this.$message.error("保存失败");
                }
            });
        },
        handleAvatarSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            //this.companyInfo.logo = constants.baseURL.API +  res.data.url;
            this.logoImage = this.formatURL(res.data.url);
            this.companyInfo.logo = res.data.url;
        },
        handleBGSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            //this.companyInfo.logo = constants.baseURL.API +  res.data.url;
            this.backImg = this.formatURL(res.data.url);
            this.companyInfo.backImg = res.data.url;
        },
        handlePPSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            //this.companyInfo.logo = constants.baseURL.API +  res.data.url;
            this.ppIconImage = this.formatURL(res.data.url);
            this.companyInfo.siteIcon = res.data.url;
        },
        handleStoreSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            //this.companyInfo.logo = constants.baseURL.API +  res.data.url;
            this.storeIconImage = this.formatURL(res.data.url);
            this.companyInfo.storeIcon = res.data.url;
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // // if (!isJPG) {
            // // this.$message.error('上传头像图片只能是 JPG 格式!');
            // // }
            // if (!isLt2M) {
            // this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            return true;//isJPG && isLt2M;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        formatURL(url) {
            let resUrl = "";
            if(url) {
                resUrl = constants.baseURL.API + url;
            }
            return resUrl;
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.upload-demo {
    display: inline-block;
}
.upload-btn {
    position: relative;
}
.system-container .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    /* overflow: hidden; */
  }
  .system-container .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .system-container .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    /* width: 178px;
    height: 178px; */
    line-height: 178px;
    text-align: center;
  }
  .system-container .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .system-container .avatar1 {
    width: 30px;
    height: 34px;
    display: block;
  }
  .companyIcon {
      background: #eee;
      width: 340px;
      height: 200px;
      margin-top: 20px;
      padding: 20px;
  }
</style>
